import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';

import { CdkScrollable } from '@angular/cdk/scrolling';
import { LayoutDirective } from '../../../directives/layout.directive';
import { CollectedPrize } from '../../../models/collection';
import { SlashkitAssets } from '../../../models/config';
import { LotteryMachine } from '../../../models/lottery';
import { ImageSizePipe } from '../../../pipes/image-size.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { SecretImagePipe } from '../../../pipes/secret-image.pipe';
import { ButtonComponent } from '../../button/button.component';
import { ShareComponent } from '../../share/share.component';

export interface ShareLotteryResultConfig {
  machine: LotteryMachine;
  rarity: LotteryMachine;
  prize: CollectedPrize;
}

@Component({
  selector: 'sl-share-lottery-result-modal',
  templateUrl: './share-lottery-result-modal.component.html',
  styleUrls: ['./share-lottery-result-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CdkScrollable,
    MatDialogContent,
    LayoutDirective,
    ButtonComponent,
    ShareComponent,
    ImageSizePipe,
    LocalePipe,
    SecretImagePipe,
  ],
})
export class ShareLotteryResultModalComponent implements OnInit {
  readonly assets = inject(SlashkitAssets);
  logoImage = this.assets.logoImage;

  readonly data = inject<ShareLotteryResultConfig>(MAT_DIALOG_DATA);
  private readonly dialogRef = inject<MatDialogRef<ShareLotteryResultConfig>>(MatDialogRef);

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  sharePath(state: ShareLotteryResultConfig) {
    return `/store/${state.machine.channel.id}/lottery/${state.machine.id}`;
  }
}
