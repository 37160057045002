import { Provider } from '@angular/core';

import { SlashkitAssets, SlashkitConfig } from '../models/config';

export function provideSlashkitConfig(config: SlashkitConfig, assets: SlashkitAssets): Provider {
  return [
    {
      provide: SlashkitConfig,
      useValue: config,
    },
    {
      provide: SlashkitAssets,
      useValue: assets,
    },
  ];
}
