import { Injectable, inject } from '@angular/core';
import { register } from 'swiper/element';

import { AnalyticsService } from './services/analytics.service';
import { AppUpdateService } from './services/app-update.service';
import { LandingService } from './services/landing.service';
import { TenantService } from './services/tenant.service';
import { TitleService } from './services/title.service';
import { ViewportService } from './services/viewport.service';

@Injectable({ providedIn: 'root' })
export class SlashkitService {
  private appUpdateService = inject(AppUpdateService);
  private titleService = inject(TitleService);
  private viewportService = inject(ViewportService);
  private analyticsService = inject(AnalyticsService);
  private landingService = inject(LandingService);
  private tenantService = inject(TenantService);

  initialize() {
    register();
    this.appUpdateService.initialize();
    this.titleService.initialize();
    this.viewportService.initialize();
    this.analyticsService.initialize();
    this.landingService.initialize();
    this.tenantService.initialize();
  }
}
