import { Injector } from '@angular/core';
import { genEndpoint } from '@twogate-npm/toolbox-angular';

import { apiHosts } from './config';
import { SlashkitConfig } from './models/config';
import { SessionService } from './services/auth/session.service';

export function jwtOptionsFactory(injector: Injector, config: SlashkitConfig) {
  return {
    tokenGetter: () => injector.get(SessionService).getIdToken(),
    allowedDomains: Object.values(apiHosts)
      .map((apiHost) => ({ ...apiHost, host: `${config.tenantId}.${apiHost.host}` }))
      .map((api) => new URL(genEndpoint(api)).host),
    disallowedRoutes: [/v1\/public\//],
  };
}

export function lottiePlayerFactory() {
  return import('lottie-web');
}

export function firebaseConfigFactory(config: SlashkitConfig) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return config.firebaseConfig;
}
