// Meta Reducers
import { ActionReducer, MetaReducer } from '@ngrx/store';

import { removeSession } from './session';

export function resetState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === removeSession.type) {
      state = undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return reducer(state, action);
  };
}
export const metaReducers: MetaReducer<any>[] = [resetState];
