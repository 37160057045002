<footer class="footer" slLayout>
  <div class="footer-nav">
    @for (link of navLinks; track link.href) {
      @if (!link?.hideOnFooter) {
        <a class="footer-nav-item" [slLink]="link">{{ link?.label }}</a>
      }
    }
  </div>
  <small class="footer-copy">&copy; {{ 'common.copyright' | locale }}</small>
  <small class="footer-version">v{{ slashkitVersion }}</small>
</footer>
