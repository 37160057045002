import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom, take } from 'rxjs';

import { LotteryCoupon } from '../models/lottery-coupon';

import { AlertService } from './alert.service';
import { SessionService } from './auth/session.service';
import { CouponCodeService } from './coupon-code.service';
import { LocaleService } from './locale.service';
import { LotteryCouponService } from './lottery-coupon.service';
import { StoreUrlService } from './store-url.service';
import { UserDetailService } from './user-detail.service';
import { UserService } from './user.service';

export type CouponRedirectRoute = 'machine_page' | 'consumable_coupon_page' | 'non_consumable_coupon_page';

@Injectable({ providedIn: 'root' })
export class LandingService {
  private documentRef = inject<Document>(DOCUMENT);
  private router = inject(Router);
  private couponCodeService = inject(CouponCodeService);
  private lotteryCouponService = inject(LotteryCouponService);
  private storeUrlService = inject(StoreUrlService);
  private alertService = inject(AlertService);
  private userService = inject(UserService);
  private userDetailService = inject(UserDetailService);
  private sessionService = inject(SessionService);
  private localeService = inject(LocaleService);

  private readonly couponCodeKey = 'serial_codes';

  async initialize() {
    const params = new URLSearchParams(this.documentRef.location.search);
    await this.sessionService.checkRedirect();
    await this.verifyCoupon(params);
  }

  async verifyCoupon(params: URLSearchParams) {
    const couponCodes = params.getAll(this.couponCodeKey);
    if (!couponCodes.length) {
      // シリアルコードが渡されていないときは何もしない
      return;
    }
    if (couponCodes.some((code) => !this.couponCodeService.couponValidator(code))) {
      // 渡されたコードに不正なものが含まれる場合は何もしない
      return;
    }

    const prerequisites = await this.checkCouponPrerequisites();
    if (!prerequisites) {
      return;
    }
    const coupons = await this.lotteryCouponService.activateCoupons(couponCodes);
    if (!coupons) {
      return null as null;
    }
    const redirectRoute = this.getRedirectRoute(coupons);
    if (redirectRoute === 'machine_page') {
      const targetLottery = coupons[0].machine;
      const type = targetLottery?.limited ? 'limited' : 'lottery';
      const storeTopPath = this.storeUrlService.transform(targetLottery?.channel?.id, 'store_top');
      this.router.navigateByUrl(`${storeTopPath}/${type}/${targetLottery?.id}`);
    } else if (redirectRoute === 'consumable_coupon_page') {
      this.router.navigateByUrl('/mypage/coupon/consumable');
    } else if (redirectRoute === 'non_consumable_coupon_page') {
      this.router.navigateByUrl('/mypage/coupon/non-consumable');
    }
  }

  getRedirectRoute(coupons: LotteryCoupon[]): CouponRedirectRoute {
    if (coupons.length === 1) {
      return 'machine_page';
    }

    const firstCoupon = coupons[0];
    if (coupons.every((coupon) => coupon?.machine?.id === firstCoupon.machine?.id)) {
      return 'machine_page';
    }

    if (firstCoupon.kind === 'drawing') {
      return 'consumable_coupon_page';
    } else {
      return 'non_consumable_coupon_page';
    }
  }

  private async checkCouponPrerequisites(): Promise<boolean> {
    // 会員登録が必要
    const firebaseUser = await this.sessionService.getFirebaseUser();
    if (!firebaseUser || firebaseUser?.isAnonymous) {
      return await this.unauthenticated();
    }

    // 生年月日登録が必須
    const user = await firstValueFrom(this.userService.fetchCurrentUser().pipe(take(1)));
    if (!user?.dateOfBirth) {
      this.alertService.dismissLoader();
      return await this.ageVerificationRequired();
    }

    return true;
  }

  private async ageVerificationRequired(): Promise<boolean> {
    await this.alertService.showNoCancellableAlert({
      header: this.localeService.translate('shared.alert.defaultHeaderText'),
      message: this.localeService.translate('shared.landing.ageVerificationAlertMessage'),
      buttons: [{ text: this.localeService.translate('shared.landing.ageVerificationAlertButtonText') }],
    });
    const result = await this.userDetailService.showDateOfBirthModal();
    return result ? this.checkCouponPrerequisites() : false;
  }

  private async unauthenticated(): Promise<boolean> {
    await this.alertService.showNoCancellableAlert({
      header: this.localeService.translate('shared.alert.defaultHeaderText'),
      message: this.localeService.translate('shared.landing.unauthorizeAlertMessage'),
      buttons: [{ text: this.localeService.translate('shared.landing.unauthorizeAlertButtonText') }],
    });
    const result = await this.sessionService.signUp();
    return result ? this.checkCouponPrerequisites() : false;
  }
}
