import { InjectionToken, Injector, Provider } from '@angular/core';
import { FirebaseOptions, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';

import { firebaseConfigFactory } from './factories';
import { SlashkitConfig } from './models/config';

const FIREBASE_OPTIONS = new InjectionToken<FirebaseOptions>('FIREBASE_OPTIONS');

export function provideAngularFire(): Provider {
  return [
    provideFirebaseApp(
      (injector: Injector) => {
        const options = injector.get(FIREBASE_OPTIONS);
        return initializeApp(options);
      },
      [FIREBASE_OPTIONS],
    ),
    provideAuth(() => {
      return getAuth();
    }),
    {
      provide: FIREBASE_OPTIONS,
      deps: [SlashkitConfig],
      useFactory: firebaseConfigFactory,
    },
  ];
}
