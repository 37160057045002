<div class="tab" slLayout>
  <div class="tab-list">
    <a routerLink="/store" routerLinkActive="current" class="tab-list-item">
      <sl-icon class="icon" name="gift"></sl-icon>
      <div class="label">{{ 'shared.breadcrumbs.storeTop' | locale }}</div>
    </a>
    <a routerLink="/exchange" routerLinkActive="current" class="tab-list-item">
      <sl-icon class="icon" name="shipment"></sl-icon>
      <div class="label">{{ 'shared.breadcrumbs.exchange' | locale }}</div>
      @if (hasUnshippedCheckout) {
        <sl-icon class="badge" name="notice"></sl-icon>
      }
    </a>
    <a routerLink="/mypage" routerLinkActive="current" class="tab-list-item">
      <sl-icon class="icon" name="account"></sl-icon>
      <div class="label">{{ 'shared.breadcrumbs.mypage' | locale }}</div>
      @if (incompleteUserInformation) {
        <sl-icon class="badge" name="notice"></sl-icon>
      }
    </a>
  </div>
</div>
