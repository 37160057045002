import { FactoryProvider } from '@angular/core';

import { mergeDeep } from '../libs/deep-merge';
import {
  DEFAULT_LOCALE_ID,
  selectLocaleSync,
  selectLocalizeOption,
  SLASHKIT_CURRENCY_OPTION,
  SLASHKIT_LOCALE,
  SLASHKIT_LOCALE_ID,
  SLASHKIT_LOCALIZE_OPTION,
  SlashkitCurrencyOption,
  SlashkitLocale,
  SlashkitLocaleId,
  SlashkitLocalizeOption,
} from '../models/locale';

export function provideSlashkitLocale(
  locale: SlashkitLocale,
  localeId?: SlashkitLocaleId,
  localizeOption?: Partial<SlashkitLocalizeOption>,
  currencyOption?: Partial<SlashkitCurrencyOption>,
): FactoryProvider[] {
  return [
    {
      provide: SLASHKIT_LOCALE_ID,
      useFactory: () => {
        if (localeId) {
          return localeId;
        } else {
          return DEFAULT_LOCALE_ID;
        }
      },
    },
    {
      provide: SLASHKIT_LOCALE,
      useFactory: (id: SlashkitLocaleId) => {
        const defaultLocale = selectLocaleSync(id);
        if (locale) {
          return mergeDeep(defaultLocale, locale);
        } else {
          return defaultLocale;
        }
      },
      deps: [SLASHKIT_LOCALE_ID],
    },
    {
      provide: SLASHKIT_LOCALIZE_OPTION,
      useFactory: (id: SlashkitLocaleId) => {
        const defaultOption = selectLocalizeOption(id);
        if (localizeOption) {
          return mergeDeep(defaultOption, localizeOption);
        } else {
          return defaultOption;
        }
      },
      deps: [SLASHKIT_LOCALE_ID],
    },
    {
      provide: SLASHKIT_CURRENCY_OPTION,
      useFactory: (id: SlashkitLocaleId) => {
        return {
          currencyCodeId: currencyOption?.currencyCodeId ?? id,
          currencyDisplay: currencyOption?.currencyDisplay ?? 'symbol',
          digitsInfo: currencyOption?.digitsInfo,
        };
      },
      deps: [SLASHKIT_LOCALE_ID],
    },
  ];
}
