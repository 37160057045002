import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { versions } from '@version';

import { LayoutDirective } from '../../directives/layout.directive';
import { LinkDirective } from '../../directives/link.directive';
import { SlashkitAssets } from '../../models/config';
import { LocalePipe } from '../../pipes/locale.pipe';

@Component({
  selector: 'sl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LayoutDirective, LinkDirective, LocalePipe],
})
export class FooterComponent implements OnInit {
  readonly assets = inject(SlashkitAssets);
  navLinks = this.assets.navLinks;

  // slashkit と slashkit-plus のバージョンは基本的に一致しているので、Plusでも slashkit のバージョンを表示している
  slashkitVersion = versions.slashkitVersion;

  ngOnInit() {}
}
