import { NativeDateAdapter } from '@angular/material/core';
import _dayjs from 'dayjs';
import 'dayjs/locale/ja';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

const dayjs = _dayjs;

// Custom Date-Formats and Adapter (using https://github.com/iamkun/dayjs)

export const appDateFormats = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMMM',
  },
};

export class AppDateAdapter extends NativeDateAdapter {
  constructor(matDateLocale: string) {
    super(matDateLocale);

    // Initalize DayJS-Parser
    dayjs.locale('ja');
    dayjs.extend(customParseFormat);
    dayjs.extend(localizedFormat);
  }

  override parse(value: any): Date | null {
    return dayjs(value, 'YYYY/MM/DD').toDate();
  }

  override format(date: Date, displayFormat: any): string {
    return dayjs(date).format(displayFormat);
  }
}
