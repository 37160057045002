import { ActionReducerMap } from '@ngrx/store';

import * as fromCollection from './collection';
import * as fromCountry from './country';
import * as fromLotteryCheckout from './lottery-checkout';
import * as fromLotteryCoupon from './lottery-coupon';
import * as fromLotteryMachine from './lottery-machine';
import * as fromLotteryMachineGroup from './lottery-machine-group';
import * as fromLotteryStore from './lottery-store';
import * as fromNotification from './notification';
import * as fromPayment from './payment';
import * as fromSession from './session';
import * as fromShipment from './shipment';
import * as fromShippingPeriod from './shipping-period';
import * as fromStoreBanner from './store-banner';
import * as fromStoreNotification from './store-notification';
import * as fromUser from './user';

export interface AppState {
  collection: fromCollection.State;
  [fromCountry.featureKey]: fromCountry.State;
  lotteryCheckout: fromLotteryCheckout.State;
  lotteryCoupon: fromLotteryCoupon.State;
  lotteryMachine: fromLotteryMachine.State;
  lotteryMachineGroup: fromLotteryMachineGroup.State;
  lotteryStore: fromLotteryStore.State;
  notification: fromNotification.State;
  payment: fromPayment.State;
  session: fromSession.State;
  shipment: fromShipment.State;
  shippingPeriod: fromShippingPeriod.State;
  storeBanner: fromStoreBanner.State;
  storeNotification: fromStoreNotification.State;
  user: fromUser.State;
}

export const reducers: ActionReducerMap<AppState> = {
  collection: fromCollection.reducer,
  [fromCountry.featureKey]: fromCountry.reducer,
  lotteryCheckout: fromLotteryCheckout.reducer,
  lotteryCoupon: fromLotteryCoupon.reducer,
  lotteryMachine: fromLotteryMachine.reducer,
  lotteryMachineGroup: fromLotteryMachineGroup.reducer,
  lotteryStore: fromLotteryStore.reducer,
  notification: fromNotification.reducer,
  payment: fromPayment.reducer,
  session: fromSession.reducer,
  shipment: fromShipment.reducer,
  shippingPeriod: fromShippingPeriod.reducer,
  storeBanner: fromStoreBanner.reducer,
  storeNotification: fromStoreNotification.reducer,
  user: fromUser.reducer,
};

export { metaReducers } from './meta';
