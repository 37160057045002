import { ApplicationRef, Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import _compareVersions from 'compare-versions';
import { concat, filter, first, fromEvent, interval, merge, throttleTime } from 'rxjs';

import { AlertComponent } from '../components/alert/alert.component';
import { SlashkitConfig } from '../models/config';
import { AppData } from '../models/service-worker';
import { WINDOW_REF } from '../providers/window';

import { LocaleService } from './locale.service';

const compareVersions = _compareVersions;

@Injectable({ providedIn: 'root' })
export class AppUpdateService {
  private appRef = inject(ApplicationRef);
  private updates = inject(SwUpdate, { optional: true });
  private dialog = inject(MatDialog);
  private config = inject(SlashkitConfig);
  private windowRef = inject<Window>(WINDOW_REF);
  private localeService = inject(LocaleService);

  initialize() {
    if (this.config.production && this.updates) {
      this.updates.versionUpdates
        .pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'))
        .subscribe((event) => {
          if (this.updateImmediately(event)) {
            this.activateUpdate(event.latestVersion?.appData as AppData);
          }
        });
      this.checkForUpdate();
    }
  }

  updateImmediately(event: VersionReadyEvent): boolean {
    if ((event.latestVersion.appData as AppData)?.showPopup === 'force') {
      return true;
    } else if ((event.latestVersion.appData as AppData)?.showPopup === 'none') {
      return false;
    }
    const currentVersion = (event.currentVersion.appData as AppData)?.version;
    const availableVersion = (event.latestVersion.appData as AppData)?.version;
    return compareVersions.validate(currentVersion) && compareVersions.validate(availableVersion)
      ? compareVersions.compare(currentVersion, availableVersion, '<')
      : false;
  }

  private checkForUpdate() {
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const refreshEvent$ = merge(interval(600000), fromEvent(this.windowRef, 'focus').pipe(throttleTime(60000)));
    const checkForUpdate$ = concat(appIsStable$, refreshEvent$);

    checkForUpdate$.subscribe(() => this.updates.checkForUpdate());
  }

  private async activateUpdate(appData?: AppData) {
    await this.showUpdateAlert(appData);
    await this.updates.activateUpdate();
    this.windowRef.location.reload();
  }

  private async showUpdateAlert(appData?: AppData): Promise<void> {
    return new Promise((resolve) => {
      this.dialog
        .open(AlertComponent, {
          maxWidth: '280px',
          autoFocus: false,
          data: {
            header: this.localeService.translate('shared.appUpdate.updateAlertHeader'),
            message: `${appData?.releaseNote ?? ''}<br>${appData?.version}`,
            buttons: [
              {
                text: this.localeService.translate('shared.appUpdate.updateAlertButtonText'),
                color: 'primary',
              },
            ],
          },
        })
        .afterClosed()
        .subscribe(() => resolve());
    });
  }
}
