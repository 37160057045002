import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { translateApiLocaleId } from '../models/api-locale';
import { SLASHKIT_LOCALE_ID, SlashkitLocaleId } from '../models/locale';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  private environmentService = inject(EnvironmentService);
  private locale = inject<SlashkitLocaleId>(SLASHKIT_LOCALE_ID);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let params = req.params;

    if (this.isInternalApiRequest(req.url)) {
      params = params.set('locale', translateApiLocaleId(this.locale));
    }

    const newReq = req.clone({ params });
    return next.handle(newReq);
  }

  private isInternalApiRequest(url: string): boolean {
    return url.includes(this.environmentService.api.host) || url.includes('localhost');
  }
}
