import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { versions } from '@version';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  private readonly environmentService = inject(EnvironmentService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isInternalApi(req.url)) {
      const newReq = req.clone({
        setHeaders: {
          'x-app-version': versions.slashkitVersion,
        },
      });
      return next.handle(newReq);
    }

    return next.handle(req);
  }

  private isInternalApi(url: string): boolean {
    // NOTE: cannot contain hostname in url excluding hostname
    return url.includes(this.environmentService.api.host);
  }
}
