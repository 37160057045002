<div class="countdown">
  <div
    class="day"
    [attr.data-remaining]="'components.countdownTimer.remaining' | locale"
    [attr.data-day-caption]="'components.countdownTimer.day' | locale"
  >
    <sl-flip-counter [value]="day$ | async"></sl-flip-counter>
  </div>
  <div class="timer">
    <div class="timer-value hour" [attr.data-hour-caption]="'components.countdownTimer.hour' | locale">
      <sl-flip-counter [value]="hour$ | async"></sl-flip-counter>
    </div>
    <div class="timer-separator">:</div>
    <div class="timer-value minute" [attr.data-minute-caption]="'components.countdownTimer.minute' | locale">
      <sl-flip-counter [value]="minute$ | async"></sl-flip-counter>
    </div>
    <div class="timer-separator">:</div>
    <div class="timer-value second" [attr.data-second-caption]="'components.countdownTimer.second' | locale">
      <sl-flip-counter [value]="second$ | async"></sl-flip-counter>
    </div>
  </div>
</div>
