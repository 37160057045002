import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';

import { MatRipple } from '@angular/material/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LayoutDirective } from '../../directives/layout.directive';
import { LinkDirective } from '../../directives/link.directive';
import { SlashkitAssets } from '../../models/config';
import { LocalePipe } from '../../pipes/locale.pipe';
import { ModalService } from '../../services/modal.service';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'sl-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LayoutDirective, RouterLink, RouterLinkActive, IconComponent, MatRipple, LinkDirective, LocalePipe],
})
export class NavComponent implements OnInit {
  readonly modalService = inject(ModalService);
  readonly assets = inject(SlashkitAssets);

  @Input() hasUnshippedCheckout = false;
  @Input() incompleteUserInformation = false;
  logo = this.assets.logoImage;
  businessLink = this.assets?.businessLink;

  ngOnInit() {}
}
