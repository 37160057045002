<div mat-dialog-content class="item" slLayout>
  <div class="machine-name">{{ data?.machine?.name }}</div>
  <div class="rarity-name">{{ data?.rarity?.name }}</div>
  <div class="prize-name">{{ data?.prize?.name }}</div>
  <div class="prize-image">
    <img [src]="data?.prize | secretImage | imageSize: 'medium'" alt="" />
  </div>

  <div class="buttons">
    <!-- <sl-button color="basic" type="filled">ダウンロード</sl-button> -->
    <sl-button color="basic" type="stroked" (click)="close()">{{
      'shared.modal.defaultDismissText' | locale
    }}</sl-button>
  </div>

  <div class="footer">
    <sl-share
      [text]="'shared.share.shareText' | locale: { appName: 'common.appName' | locale, itemName: data?.prize?.name }"
      [path]="sharePath(data)"
    ></sl-share>
    <div class="logo">
      <img [src]="logoImage.src" alt="" />
    </div>
  </div>
</div>
