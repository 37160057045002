import { Provider } from '@angular/core';
import { provideStore } from '@ngrx/store';
// import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { metaReducers } from './meta';
import { reducers } from './symbols';

export function provideSlashkitStore(): Provider {
  return [
    provideStore(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    // FIXME: environmentによってModuleから除外する方法が分からない
    // releaseビルドの際は手動でコメントアウトする
    // StoreDevtoolsModule.instrument({ maxAge: 50 }),
  ];
}
