import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, Injectable, NgZone } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { filter } from 'rxjs';

import { SlashkitConfig } from './models/config';
import { getFirebaseUser } from './store/session';
import { AppState } from './store/symbols';

type Errors = Error | ErrorEvent | HttpErrorResponse;

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private ngZone = inject(NgZone);
  private config = inject(SlashkitConfig);
  private store = inject<Store<AppState>>(Store);

  private bannedBreadcrumbUrls: string[] = ['https://www.google-analytics.com', 'https://stats.g.doubleclick.net'];

  constructor() {
    Sentry.init({
      enabled: this.config.production && location.host !== 'localhost:4200',
      environment: this.config.environment,
      debug: this.config.environment !== 'production',
      dsn:
        this.config.environment === 'production'
          ? 'https://e44a09108cff444d8c20987890691215@o23268.ingest.sentry.io/5494258'
          : 'https://4452b66a04ef4e418f5f8897301a1792@o23268.ingest.sentry.io/5494257',
      transport: Sentry.Transports.FetchTransport,
      tracesSampleRate: this.config.environment === 'production' ? 0.01 : 1,
      release: this.config.versions.revision,
      ignoreErrors: [
        /network error/i,
        /invalid action/i,
        /loading chunk/i,
        /No Firebase App/i,
        /Non-Error exception captured/i,
        // https://github.com/google/recaptcha/issues/269
        // reCAPTCHA の Timeout エラーで放置していると必ず起きるので無視
        /(Uncaught)?\s*\(in promise\):?\s*Timeout/i,
        // firebase 9 にあげてからこのエラーがよく出るので無視
        /Firebase: Error \(auth\/network-request-failed\)/,
        // web animation api のサポートが無いブラウザー(古いブラウザー)ではエラーがでるので無視
        /animate is not a function/,
      ],
      integrations: [new Sentry.Integrations.GlobalHandlers({ onerror: false, onunhandledrejection: false })],
      beforeBreadcrumb: (breadcrumb, hint) => this.filterBreadcrumb(breadcrumb, hint),
    });
    this.store
      .pipe(
        select(getFirebaseUser),
        filter((u) => !!u),
      )
      .subscribe((user) => {
        Sentry.configureScope((scope) => {
          scope.setUser({ id: user?.uid });
          scope.setTag('tenant', this.config.tenantId);
        });
      });
    Sentry.configureScope((scope) => {
      scope.setTags(this.config.versions);
    });
  }

  extractError(err: Errors & { ngOriginalError: Errors }) {
    const error = err && err.ngOriginalError ? err.ngOriginalError : err;

    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse) {
      // ネットワーク不通と400番以上のエラーではSentryにあげない
      if (error.status === 0 || error.status >= 400) {
        return null;
      }

      if (error.error instanceof Error) {
        return error.error;
      }

      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      return error.message;
    }

    if (error?.message) {
      Sentry.addBreadcrumb({
        message: 'Unknown error',
        data: error,
      });
      return new Error(error.message);
    }

    // unknown error
    this.ngZone.runOutsideAngular(() => {
      Sentry.withScope((scope) => {
        scope.addBreadcrumb({
          message: 'Unknown error',
          data: error,
        });
      });
      Sentry.captureException(err);
    });
    return null;
  }

  handleError(error: any): void {
    const extractedError = this.extractError(error);

    if (extractedError !== null) {
      if (!this.config.production) {
        console.error(extractedError);
      }
      this.ngZone.runOutsideAngular(() => {
        Sentry.captureException(extractedError);
      });
    }
  }

  private filterBreadcrumb(breadcrumb: Sentry.Breadcrumb, hint: Sentry.BreadcrumbHint) {
    if (
      breadcrumb.category === 'xhr' &&
      breadcrumb.type === 'http' &&
      this.bannedBreadcrumbUrls.some((v) => (breadcrumb.data?.['url'] as string).includes(v))
    ) {
      return null;
    }
    if (
      breadcrumb.category === 'xhr' &&
      breadcrumb.type === 'http' &&
      /^.*\.svg$/.test(breadcrumb.data?.['url'] as string)
    ) {
      return null;
    }
    return breadcrumb;
  }
}

export const errorHandlerProvider = {
  provide: ErrorHandler,
  useClass: SentryErrorHandler,
};
