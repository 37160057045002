import { Component, EventEmitter, NgZone, OnDestroy, OnInit, inject } from '@angular/core';
import { GuardsCheckEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, fromEvent, map, merge, takeUntil } from 'rxjs';

import { WINDOW_REF } from '../../providers/window';
import { ScrollService } from '../../services/scroll.service';

@Component({
  selector: 'sl-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss'],
  standalone: true,
})
export class ScrollTopComponent implements OnInit, OnDestroy {
  showButton = false;

  private readonly scrollService = inject(ScrollService);
  private readonly zone = inject(NgZone);
  private readonly router = inject(Router);
  private readonly windowRef = inject<Window>(WINDOW_REF);

  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>();
  private readonly threshold = 20;

  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      merge(
        fromEvent(this.windowRef, 'scroll'),
        fromEvent(this.windowRef, 'resize'),
        this.router.events.pipe(filter((event) => event instanceof GuardsCheckEnd)),
      )
        .pipe(
          map(() => this.windowRef.document.documentElement.scrollTop),
          map((scrollTop: number) => scrollTop > this.threshold),
          distinctUntilChanged(),
          takeUntil(this.onDestroy$),
        )
        .subscribe((show) => {
          this.zone.run(() => {
            this.showButton = show;
          });
        });
    });
  }

  ngOnDestroy() {
    this.onDestroy$.emit();
  }

  scrollTop() {
    this.scrollService.scrollTop();
  }
}
