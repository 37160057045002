import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AnonymousRequestBlockInterceptor } from './anonymous-request-block.interceptor';
import { AppVersionInterceptor } from './app-version.interceptor';
import { LocaleInterceptor } from './locale.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AnonymousRequestBlockInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AppVersionInterceptor, multi: true },
];
