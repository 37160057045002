import { inject } from '@angular/core';
import { User as FirebaseUser } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, firstValueFrom, map, mergeMap, of, take, tap } from 'rxjs';

import { User } from '../models/user';
import { AlertService } from '../services/alert.service';
import { SessionService } from '../services/auth/session.service';
import { LocaleService } from '../services/locale.service';
import { UserService } from '../services/user.service';
import { getFirebaseUser } from '../store/session';
import { AppState } from '../store/symbols';

export const authGuard: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Promise<UrlTree | boolean> => {
  const store = inject<Store<AppState>>(Store);
  const sessionService = inject(SessionService);
  const userService = inject(UserService);
  const alertService = inject(AlertService);
  const localeService = inject(LocaleService);

  const loaderRef = await alertService.showLoader(localeService.translate('shared.loader.defaultMessage'));

  return firstValueFrom(
    store.pipe(
      select(getFirebaseUser),
      filter((firebaseUser) => firebaseUser !== undefined),
      take(1),
      mergeMap((firebaseUser) => (firebaseUser ? of(firebaseUser) : sessionService.signInAnonymously())),
      tap(() => sessionService.initialized()),
      mergeMap((firebaseUser) =>
        userService.fetchCurrentUser().pipe(map((user) => [user, firebaseUser] as [User, FirebaseUser])),
      ),
      map(() => {
        loaderRef.close();
        return true;
      }),
    ),
  );
};
