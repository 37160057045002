import { Component, OnInit, Signal, computed, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SafeHtmlPipe } from '@twogate-npm/toolbox-angular';

import { ButtonComponent } from '../../components/button/button.component';
import { LoadingDirective } from '../../directives/loading.directive';
import { LocalePipe } from '../../pipes/locale.pipe';
import { StripeService } from '../../services/stripe.service';

export type ThreeDSecureResult = '3DS-authentication-complete' | '3DS-authentication-failed';

@Component({
  template: `
    <div class="page" *slLoading="!loading()">
      @if (!success()) {
        <div class="body">
          <div class="heading">{{ 'pages.threeDomainSecureComplete.failHeading' | locale }}</div>
          <div class="message">{{ 'pages.threeDomainSecureComplete.failMessage' | locale }}</div>
          <sl-button class="close-button" type="stroked" (click)="close()">{{
            'pages.threeDomainSecureComplete.closeButtonText' | locale
          }}</sl-button>
        </div>
      }
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
      }
      .page {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 40px 24px;
        display: flex;
        align-items: center;
      }
      .body {
        width: 100%;
        height: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
      }
      .heading {
        font-size: 1.9rem;
        font-weight: 600;
      }
      .message {
        font-size: 1.5rem;
        margin-top: 16px;
        line-height: 1.6;
      }
      .close-button {
        margin-top: 40px;
      }
    `,
  ],
  standalone: true,
  imports: [ButtonComponent, SafeHtmlPipe, LocalePipe, LoadingDirective],
})
export class ThreeDomainSecureCompletePage implements OnInit {
  stripeService = inject(StripeService);
  paymentIntentClientSecret = inject(ActivatedRoute).snapshot.queryParamMap.get('payment_intent_client_secret');
  success = signal(false);
  loading = signal(true);
  message: Signal<ThreeDSecureResult> = computed(() =>
    this.success() ? '3DS-authentication-complete' : '3DS-authentication-failed',
  );

  ngOnInit() {
    this.stripeService.ready$.subscribe(async () => {
      const paymentIntentResult = await this.stripeService.stripe.retrievePaymentIntent(this.paymentIntentClientSecret);
      this.success.set(paymentIntentResult.paymentIntent.status !== 'requires_payment_method');
      this.loading.set(false);

      if (this.success()) {
        this.close();
      }
    });
  }

  close() {
    window.parent.postMessage(this.message(), { targetOrigin: window.location.origin });
  }
}
