import { Injectable, inject } from '@angular/core';

import { apiHosts } from '../config';
import { ApiHost, SlashkitConfig } from '../models/config';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private config = inject(SlashkitConfig);

  get api(): ApiHost {
    const apiHost = this.config.api ?? apiHosts[this.config.environment];
    return {
      ...apiHost,
      host: `${this.config.tenantId}.${apiHost.host}`,
    };
  }
}
