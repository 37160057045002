import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LayoutDirective } from '../../directives/layout.directive';
import { LocalePipe } from '../../pipes/locale.pipe';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'sl-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LayoutDirective, RouterLink, RouterLinkActive, IconComponent, LocalePipe],
})
export class TabComponent implements OnInit {
  @Input() hasUnshippedCheckout = false;
  @Input() incompleteUserInformation = false;

  constructor() {}

  ngOnInit() {}
}
