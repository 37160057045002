@for (d of numberOfDigit | times; track $index) {
  <div class="digit">
    @for (i of 10 | times; track $index) {
      <div
        class="digit-flip"
        [class.is-active]="digits[d - 1] === i - 1"
        [class.is-before]="(digits[d - 1] + 11) % 10 === i - 1"
      >
        <div class="digit-flip-up">
          <div class="value">{{ i - 1 }}</div>
          <div class="shadow"></div>
        </div>
        <div class="digit-flip-down">
          <div class="value">{{ i - 1 }}</div>
          <div class="shadow"></div>
        </div>
      </div>
    }
  </div>
}
