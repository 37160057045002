import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable, of, switchMap, take } from 'rxjs';

import { getFirebaseUser } from '../store/session';

@Injectable()
export class AnonymousRequestBlockInterceptor implements HttpInterceptor {
  private readonly store = inject(Store);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 匿名ユーザーの場合は、常に private へのリクエストをブロックする
    return this.store.select(getFirebaseUser).pipe(
      filter((user) => !!user),
      take(1),
      switchMap((user) => {
        if (user?.isAnonymous && this.isPrivateApiRequest(req.url)) {
          return of(new HttpResponse({ status: 200, body: null }));
        } else {
          return next.handle(req);
        }
      }),
    );
  }

  isPrivateApiRequest(url: string): boolean {
    return /\/api\/v\d\/private\/.*/.test(url);
  }
}
