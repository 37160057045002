@if (state$ | async; as state) {
  <div class="container" slLayout>
    <sl-nav
      class="nav"
      [hasUnshippedCheckout]="state.hasUnshippedCheckout"
      [incompleteUserInformation]="state.incompleteUserInformation"
    ></sl-nav>
    <sl-tab
      class="tab"
      [hasUnshippedCheckout]="state.hasUnshippedCheckout"
      [incompleteUserInformation]="state.incompleteUserInformation"
    ></sl-tab>
    <div class="body">
      <router-outlet></router-outlet>
      <sl-footer class="body-footer"></sl-footer>
    </div>
  </div>
}

<sl-scroll-top class="scroll-top" slLayout></sl-scroll-top>
