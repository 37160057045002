import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { distinctUntilChanged, map, Observable, shareReplay, takeUntil, tap, timer } from 'rxjs';

import { AsyncPipe } from '@angular/common';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { Dayjs, DayjsService } from '../../../services/dayjs.service';
import { FlipCounterComponent } from '../flip-counter/flip-counter.component';

@Component({
  selector: 'sl-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlipCounterComponent, AsyncPipe, LocalePipe],
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  _endDateTime: Dayjs;
  @Input('endDate')
  set endDate(value: any) {
    this._endDateTime = this.dayjsService.dayjs(value ?? 0);
  }
  @Output() completed: EventEmitter<void> = new EventEmitter<void>();

  day$: Observable<number>;
  hour$: Observable<number>;
  minute$: Observable<number>;
  second$: Observable<number>;

  private readonly dayjsService = inject(DayjsService);
  private readonly onDestroy$ = new EventEmitter();

  ngOnInit() {
    const remainingSeconds$ = timer(0, 250).pipe(
      takeUntil(this.onDestroy$),
      map(() => Math.max(0, this._endDateTime.diff(this.dayjsService.dayjs(), 'second', false))),
      distinctUntilChanged(),
      tap((v) => {
        if (v === 0) {
          this.completed.emit();
        }
      }),
      shareReplay(1),
    );
    this.day$ = remainingSeconds$.pipe(
      map((remainingSeconds) => Math.floor(remainingSeconds / 60 / 60 / 24)),
      distinctUntilChanged(),
    );
    this.hour$ = remainingSeconds$.pipe(
      map((remainingSeconds) => Math.floor(remainingSeconds / 60 / 60) % 24),
      distinctUntilChanged(),
    );
    this.minute$ = remainingSeconds$.pipe(
      map((remainingSeconds) => Math.floor(remainingSeconds / 60) % 60),
      distinctUntilChanged(),
    );
    this.second$ = remainingSeconds$.pipe(
      map((remainingSeconds) => remainingSeconds % 60),
      distinctUntilChanged(),
    );
  }

  ngOnDestroy() {
    this.onDestroy$.emit();
  }
}
