import { SlashkitLocaleId } from './locale';

export type ServerLocaleId =
  | 'af'
  | 'ar'
  | 'az'
  | 'be'
  | 'bg'
  | 'bn'
  | 'bs'
  | 'ca'
  | 'cs'
  | 'cy'
  | 'da'
  | 'de-AT'
  | 'de-CH'
  | 'de-DE'
  | 'de'
  | 'el-CY'
  | 'el'
  | 'en-AU'
  | 'en-CA'
  | 'en-CY'
  | 'en-GB'
  | 'en-IE'
  | 'en-IN'
  | 'en-NZ'
  | 'en-US'
  | 'en-ZA'
  | 'en'
  | 'eo'
  | 'es-419'
  | 'es-AR'
  | 'es-CL'
  | 'es-CO'
  | 'es-CR'
  | 'es-EC'
  | 'es-ES'
  | 'es-MX'
  | 'es-NI'
  | 'es-PA'
  | 'es-PE'
  | 'es-US'
  | 'es-VE'
  | 'es'
  | 'et'
  | 'eu'
  | 'fa'
  | 'fi'
  | 'fr-CA'
  | 'fr-CH'
  | 'fr-FR'
  | 'fr'
  | 'gl'
  | 'he'
  | 'hi-IN'
  | 'hi'
  | 'hr'
  | 'hu'
  | 'id'
  | 'is'
  | 'it-CH'
  | 'it'
  | 'ja'
  | 'ka'
  | 'km'
  | 'kn'
  | 'ko'
  | 'lb'
  | 'lo'
  | 'lt'
  | 'lv'
  | 'mg'
  | 'mk'
  | 'ml'
  | 'mn'
  | 'mr-IN'
  | 'ms'
  | 'nb'
  | 'ne'
  | 'nl'
  | 'nn'
  | 'oc'
  | 'or'
  | 'pa'
  | 'pl'
  | 'pt-BR'
  | 'pt'
  | 'rm'
  | 'ro'
  | 'ru'
  | 'sk'
  | 'sl'
  | 'sq'
  | 'sr'
  | 'sv-SE'
  | 'sv'
  | 'sw'
  | 'ta'
  | 'te'
  | 'th'
  | 'tl'
  | 'tr'
  | 'tt'
  | 'ug'
  | 'uk'
  | 'ur'
  | 'uz'
  | 'vi'
  | 'wo'
  | 'zh-CN'
  | 'zh-HK'
  | 'zh-TW'
  | 'zh-YUE';

// SlashkitLocaleId と ServerLocaleId が一致しない場合に変更するためのもの
// default ではそのまま通す
export function translateApiLocaleId(id: SlashkitLocaleId): ServerLocaleId {
  switch (id) {
    case 'ja-JP':
      return 'ja';
    case 'zh-Hans':
      return 'zh-CN';
    case 'zh-Hant':
      return 'zh-TW';
    case 'zh-Hant-HK':
      return 'zh-HK';
    default:
      return id;
  }
}
