import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { redirectWithQueryParams } from './guards/redirect.guard';
import { EmptyPage } from './pages/empty/empty.page';
import { MainLayoutPage } from './pages/layout/main-layout/main-layout.page';
import { ThreeDomainSecureCompletePage } from './pages/three-domain-secure-complete/three-domain-secure-complete.page';

export const appRouteChildren: Routes = [
  {
    path: '',
    component: EmptyPage,
    canActivate: [redirectWithQueryParams('/store')],
  },
  {
    path: 'store',
    loadChildren: () => import('./pages/store/store.routing'),
  },
  {
    path: 'exchange',
    loadChildren: () => import('./pages/exchange/exchange.routing'),
  },
  {
    path: 'mypage',
    loadChildren: () => import('./pages/mypage/mypage.routing'),
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.routing'),
  },
  {
    path: 'preview',
    loadChildren: () => import('./pages/preview/preview.routing'),
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.routing'),
  },
];

export const appRoutes = [
  {
    path: '',
    canActivate: [authGuard],
    component: MainLayoutPage,
    children: appRouteChildren,
  },
];

export const secondaryRoutes: Routes = [
  {
    path: 'redirect',
    children: [
      {
        path: 'three-d-secure',
        component: ThreeDomainSecureCompletePage,
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/error/not-found',
  },
];
