import { Platform } from '@angular/cdk/platform';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom, Injector, Provider } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { IonicStorageModule } from '@ionic/storage-angular';
import {
  genEndpoint,
  TbxHttpInterceptorConfig,
  TbxHttpInterceptorConfigToken,
  tbxHttpInterceptors,
} from '@twogate-npm/toolbox-angular';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';

import { errorHandlerProvider } from './error-handler';
import { jwtOptionsFactory, lottiePlayerFactory } from './factories';

import { httpInterceptorProviders } from './http-interceptors/symbols';
import { AppDateAdapter, appDateFormats } from './libs/date-formats';
import { SlashkitAssets, SlashkitConfig } from './models/config';
import { SlashkitCurrencyOption, SlashkitLocale, SlashkitLocaleId, SlashkitLocalizeOption } from './models/locale';

import { provideAngularFire } from './angular-fire-providers';
import { provideSlashkitConfig } from './providers/config';
import { provideSlashkitLocale } from './providers/locale';
import { EnvironmentService } from './services/environment.service';
import { provideSlashkitStore } from './store/providers';

export function provideSlashkit(
  config: SlashkitConfig,
  assets: SlashkitAssets,
  locale?: SlashkitLocale,
  {
    localeId,
    currencyOption,
    localizeOption,
  }: {
    localeId?: SlashkitLocaleId;
    currencyOption?: Partial<SlashkitCurrencyOption>;
    localizeOption?: Partial<SlashkitLocalizeOption>;
  } = {},
): Provider {
  return [
    importProvidersFrom(
      JwtModule.forRoot({
        jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          useFactory: jwtOptionsFactory,
          deps: [Injector, SlashkitConfig, EnvironmentService],
        },
      }),
      IonicStorageModule.forRoot(),
    ),
    provideHttpClient(withInterceptors([...tbxHttpInterceptors]), withInterceptorsFromDi()),
    provideLottieOptions({ player: lottiePlayerFactory }),
    provideCacheableAnimationLoader(),
    httpInterceptorProviders,
    errorHandlerProvider,
    {
      provide: TbxHttpInterceptorConfigToken,
      useFactory: (env: EnvironmentService): TbxHttpInterceptorConfig => {
        return {
          api: {
            endpoint: genEndpoint(env.api, '/api'),
          },
        };
      },
      deps: [EnvironmentService],
    },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: appDateFormats,
    },
    provideAngularFire(),
    provideSlashkitConfig(config, assets),
    provideSlashkitStore(),
    provideSlashkitLocale(locale, localeId, localizeOption, currencyOption),
  ];
}
