import { Injectable, NgZone, inject } from '@angular/core';

import { WINDOW_REF } from '../providers/window';

export type ScrollEasingFunction = 'ease-in' | 'ease-out' | 'linear' | ((time: number) => number);

export interface ScrollOption {
  offset: number;
  duration: number;
  easing: ScrollEasingFunction;
}

@Injectable({ providedIn: 'root' })
export class ScrollService {
  private zone = inject(NgZone);
  private windowRef = inject<Window>(WINDOW_REF);

  private defaultOption: ScrollOption = {
    offset: 0,
    duration: 250,
    easing: 'ease-out',
  };

  get html() {
    return this.windowRef.document.documentElement;
  }

  scrollTop() {
    return this.doScroll(this.html);
  }

  async doScroll(target: HTMLElement, option?: ScrollOption): Promise<void> {
    const scrollOption = { ...this.defaultOption, ...option };
    return new Promise((resolve) => {
      this.zone.runOutsideAngular(() => {
        const interval = 10;
        const container = this.html;
        const start = this.html.scrollTop;
        const end = target.offsetTop + scrollOption.offset;
        const amount = end - start;
        const easing = this.easingFunction(scrollOption.easing);
        const steps = scrollOption.duration / interval;
        let step = steps;

        const animate = () => {
          const time = 1 - step / steps;
          container.scrollTop = start + amount * easing(time);
          step--;
          if (step > 0) {
            setTimeout(() => {
              requestAnimationFrame(animate);
            }, interval);
          } else {
            container.scrollTop = end;
            resolve();
          }
        };

        requestAnimationFrame(animate);
      });
    });
  }

  easingFunction(easing: ScrollEasingFunction) {
    if (typeof easing !== 'string') {
      return easing;
    }
    switch (easing) {
      case 'linear':
        return this.linear;
      case 'ease-in':
        return this.easeIn;
      case 'ease-out':
        return this.easeOut;
    }
  }

  private linear(time: number): number {
    return time;
  }

  private easeIn(time: number): number {
    return 1 - Math.cos((time * Math.PI) / 2);
  }

  private easeOut(time: number): number {
    return Math.sin((time * Math.PI) / 2);
  }
}
