/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export function mergeDeep<T>(target: T, source: T): T {
  const isObject = (obj) => obj && typeof obj === 'object' && !(obj instanceof RegExp);

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = Object.assign(targetValue, sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}
