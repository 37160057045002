import { Component, inject, OnInit } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';

import { AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../../../components/footer/footer.component';
import { NavComponent } from '../../../components/nav/nav.component';
import { ScrollTopComponent } from '../../../components/scroll-top/scroll-top.component';
import { TabComponent } from '../../../components/tab/tab.component';
import { LayoutDirective } from '../../../directives/layout.directive';
import { SessionService } from '../../../services/auth/session.service';
import { PaymentService } from '../../../services/payment.service';
import { ShipmentService } from '../../../services/shipment.service';
import { UserService } from '../../../services/user.service';
import { combineStream } from '../../../util';

export type MainLayoutState = {
  hasUnshippedCheckout: boolean;
  incompleteUserInformation: boolean;
};

@Component({
  selector: 'sl-main-layout',
  templateUrl: './main-layout.page.html',
  styleUrls: ['./main-layout.page.scss'],
  standalone: true,
  imports: [LayoutDirective, NavComponent, TabComponent, RouterOutlet, FooterComponent, ScrollTopComponent, AsyncPipe],
})
export class MainLayoutPage implements OnInit {
  state$: Observable<MainLayoutState>;

  private readonly shipmentService = inject(ShipmentService);
  private readonly paymentService = inject(PaymentService);
  private readonly userService = inject(UserService);
  private readonly sessionService = inject(SessionService);

  constructor() {
    this.state$ = combineStream({
      hasUnshippedCheckout: this.shipmentService.shippingPeriods$.pipe(
        map((shippingPeriods) => shippingPeriods?.length > 0),
      ),
      incompleteUserInformation: combineStream({
        card: this.paymentService.card$,
        user: this.userService.currentUser$,
        firebaseUser: this.sessionService.firebaseUser$,
      }).pipe(
        map(({ card, user, firebaseUser }) => {
          if (firebaseUser?.isAnonymous) {
            // 未ログイン時はバッジを表示しない
            return false;
          }
          return !card || !user?.dateOfBirth;
        }),
      ),
    });
  }

  ngOnInit() {
    forkJoin([
      this.shipmentService.fetchActiveShipments(),
      this.userService.fetchUserDetail(),
      this.paymentService.fetchCard(),
    ]).subscribe();
  }
}
