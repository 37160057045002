<nav class="nav" slLayout>
  <a class="nav-logo" routerLink="/">
    <img [src]="logo.src" [width]="logo.originalWidth" [height]="logo.originalHeight" alt="" class="nav-logo-image" />
  </a>
  <div class="nav-list">
    <a routerLink="/store" routerLinkActive="current" class="nav-list-item">
      <sl-icon class="icon" name="gift"></sl-icon>
      <div class="label">{{ 'shared.breadcrumbs.storeTop' | locale }}</div>
    </a>
    <a routerLink="/exchange" routerLinkActive="current" class="nav-list-item">
      <sl-icon class="icon" name="shipment"></sl-icon>
      @if (hasUnshippedCheckout) {
        <sl-icon class="badge" name="notice"></sl-icon>
      }
      <div class="label">{{ 'shared.breadcrumbs.exchange' | locale }}</div>
    </a>
    <a routerLink="/mypage" routerLinkActive="current" class="nav-list-item">
      <sl-icon class="icon" name="account"></sl-icon>
      @if (incompleteUserInformation) {
        <sl-icon class="badge" name="notice"></sl-icon>
      }
      <div class="label">{{ 'shared.breadcrumbs.mypage' | locale }}</div>
    </a>
  </div>
  <div class="nav-right">
    @if (businessLink) {
      <a matRipple class="business-link" [slLink]="businessLink">{{ businessLink?.label }}</a>
    }
    <a matRipple (click)="modalService.showUsageModal()" class="usage">
      <sl-icon class="icon" name="usage"></sl-icon>
      <div class="label">{{ 'shared.breadcrumbs.usage' | locale }}</div>
    </a>
  </div>
</nav>
