import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimesPipe } from '@twogate-npm/toolbox-angular';

@Component({
  selector: 'sl-flip-counter',
  templateUrl: './flip-counter.component.html',
  styleUrls: ['./flip-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TimesPipe],
})
export class FlipCounterComponent {
  @Input() numberOfDigit = 2; // 表示する桁数
  digits: number[] = [];
  @Input('value')
  set value(value: number) {
    if (this.digits.length !== this.numberOfDigit) {
      this.digits = new Array<number>(this.numberOfDigit);
    }
    for (let i = 0; i < this.numberOfDigit; i++) {
      const divisor = Math.pow(10, this.numberOfDigit - 1 - i);
      this.digits[i] = Math.floor(value / divisor) % 10;
    }
  }

  constructor() {}
}
