import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundNumber',
  standalone: true,
})
export class RoundNumberPipe implements PipeTransform {
  transform(value: number): string {
    return value < 100 ? Math.floor(value).toString() : `99+`;
  }
}
